import '../styles/About.css';
import React from "react";


function About() {

    return (
        <div id='about'>
            <div className='about-div' >
                <h2 style={{ marginBottom: '4%', color: '#5692F5' }}>About Me</h2>

                    <p>
                        I am a passionate web and software developer who  graduated from Northumbria University of Newcastle, UK. Armed with a First Class Honors degree in Computer Science, I am driven by the exciting intersection of art and functionality in software design.
                    </p>
                    <p>
                        My journey includes developing a range of projects, such as university assessments, personal ventures, or freelancing projects, showcasing my strengths in both front-end and back-end technologies.
                        My diverse skill set encompasses a multitude of languages and frameworks, complemented by certifications and a continuous drive for learning.

                    </p>

                 
                    <p>
                        Outside the digital realm, I find equal fascination in psychology and prioritize fitness activities to maintain discipline and push beyond limits.                </p>
            </div>
        </div>
    );
}

export default About;