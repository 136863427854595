import '../styles/Contact.css';
import React, { useState } from "react";


function Contact() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const messageDiv = document.getElementById('submitted');
    const submitButton = document.getElementById('submit-form');


    const handleSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('name', name);
        formData.append('email', email);
        formData.append('message', message);
        submitButton.classList.add('transparent');

        fetch("https://portfolio-387702.nw.r.appspot.com/contact",
            {
                method: 'POST',
                body: formData
            })
            .then(
                (response) => response.text()
            )
            .then(
                (json) => {
                    console.log(json);
                    // Add the 'sent' class to change the color to green
                    messageDiv.classList.add('sent');
                    submitButton.classList.remove('transparent');

                    // Remove the 'sent' class after 5 seconds to restore the original color
                    setTimeout(() => {
                        messageDiv.classList.remove('sent');
                    }, 4000);
                })
            .catch(
                (e) => {
                    console.log(e.message)
                })
        setName('');
        setEmail('');
        setMessage('');

    };


    return (
        <div id='contact'>
            <div className='contact-div'>
                <h2 style={{ marginBottom: '80px', color: '#5692F5' }}>Feel free to Contact Me</h2>

                <form onSubmit={handleSubmit}>
                    <div>
                        <span>Name</span>
                        <br />
                        <input
                            type="text"
                            className="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <span>Email</span>
                        <br />
                        <input
                            type="email"
                            className="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <span>Message</span>
                        <br />
                        <textarea
                            className="message"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit" className='submit-contact' id="submit-form">Submit</button>
                    <div className='success' id='submitted'>Your message has been sent successfully</div>

                </form>

            </div>
        </div>
    );
}

export default Contact;