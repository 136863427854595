import '../styles/Main.css';
import React from "react";
import Navigation from "./Navigation.js";
import Me from "./Me.js";
import Projects from "./Projects.js";
import About from "./About.js";
import Contact from "./Contact.js";

function Home() {
  return (
    <>
      <Navigation />
      <Me />
      <About />
      <Projects />
      <Contact />
      <div style={{ backgroundColor: 'rgb(243, 242, 242)', padding: '1rem' }}>
        <p style={{ textAlign: 'center', color: '#141414', fontSize:"18px", fontWeight:'600' }}>Copyright © 2023. All rights are reserved</p>
      </div>
    </>
  );
}

export default Home;