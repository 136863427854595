
import '../styles/Projects.css';
import React from "react";
import { useEffect, useState } from "react";
import demo from "../images/icons/share.png";
import github from "../images/icons/github.svg";



function Projects() {

    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetch('https://portfolio-387702.nw.r.appspot.com/projects')
            .then((response) => response.json())
            .then((json) => {
                // console.log(json)
                setProjects(json);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, []);

    const displayProjects = projects.map((project, index) => (
        <div
            key={index}
            value={project.ID}
            className="project-info-div"
        >
            <h3 style={{ textAlign: "center" }}>{project.name}</h3>

            <section className="project-info-section">
                <div className="div">
                    <img src={project.image} title="project-display" alt="project-display"
                        style={{
                            width: '90%', maxWidth:'90rem',height: '100%', objectFit: 'cover', borderRadius: '20px', boxShadow: '0 2px 20px rgba(0, 0, 0, 0.4)'
                        , marginTop: '2rem', cursor:'pointer'}}
                        onClick={() => {
                            window.open([[project.demo]], '_blank');
                        }}
                        className='project-img'
                    />
                </div>
                <div className='project-text'>
                    <p style={{ marginTop: '30px', marginBottom: '30px', textAlign: "center" }}>{project.description}</p>
                    <div className='technologies-div'>{project.technologies.map((technology, index) => (
                        <span key={index} className='technologies'>{technology}</span>
                    ))}</div>
                    <div className='git-demo-div'>
                        <div className='git-demo'
                            onClick={() => {
                                window.open([[project.github]], '_blank');
                            }}>
                            <p style={{ marginTop: '0.8rem' }} className='demos-p'>Github</p>
                            <img src={github} alt="github"
                                style={{ width: '3rem',marginLeft:'5px' }}></img>
                        </div>

                        <div className='git-demo'
                            onClick={() => {
                                window.open([[project.demo]], '_blank');
                            }}
                        >
                            <p style={{ marginTop: '0.8rem' }} className='demos-p'>Demo</p>
                            <img src={demo} alt="demo"
                                style={{ width: '3.1rem', height:'3.2rem',marginLeft:'5px' }}></img>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    ));
    return (
        <div id='projects' style={{backgroundColor: 'rgb(243, 242, 242)'}}>
        <div style={{ marginTop: "35px", paddingTop: "8rem", paddingBottom: '100px', width:'80%', maxWidth:'1400px', margin:'0 auto'}}>
            {!loading && <h2 style={{ marginBottom: '4%', color:'#5692F5' }}>Projects</h2>}
            {!loading && displayProjects}
            {loading && <h3 style={{ textAlign:'center', color:'#5692F5' }}>Loading...</h3>}
        </div>
        </div>
    );
}

export default Projects;