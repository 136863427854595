import '../styles/Nav.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';


function Navigation() {
  const pageUp = () => {
    window.scrollTo({ top: (0, 0), behavior: "smooth" });
  };



  return (
    <div className='nav-div'>
    <Navbar bg="none" expand="lg" className='main-nav'>
      <Container>
            <Navbar.Brand href="#home" onClick={pageUp}>
              <span className="logo">Dev</span>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" style={{ position: 'fixed', top: '20px', right:'20px' }} />
          <div className='options'>

            <Navbar.Collapse aria-controls="responsive-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link href="#home">
                  <span className="option">Home</span>
                </Nav.Link>
                <Nav.Link href="#about">
                  <span className="option">About</span>
                </Nav.Link>
                <Nav.Link href="#projects">
                  <span className="option">Projects</span>
                </Nav.Link>
                <Nav.Link href="#contact">
                  <span className="option">Contact</span>
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>

          </div>

      </Container>
    </Navbar>
    </div>
  );
}

export default Navigation;
