import "../styles/Me.css";
import Map from './Map';
import html from "../images/icons/html.svg";
import css from "../images/icons/css.svg";
import Javascript from "../images/icons/javascript.svg";
import React from "../images/icons/react.svg";
import PHP from "../images/icons/php.svg";
import mongodb from "../images/icons/mongodb.svg";
import nodejs from "../images/icons/nodejs.svg";
import sqlite from "../images/icons/sqlite.svg";
import firebase from "../images/icons/firebase.svg";
import bootstrap from "../images/icons/bootstrap.svg";
import dotnet from "../images/icons/dotnet.svg";
import me from "../images/me.jpeg";
import profile from "../images/icons/profile.svg";


function Me() {
  return (
    <div className="home" id='home'>
      <section className='home-section'>
        <div className="container">
          <div className="content">
            <div className="hero-main">
              <div className="hero-text">
                <h1 className="heading1">Web / Software Developer</h1>
                <p className="text-p">
                  Hi, I am Razvan, a dedicated Entry Level Developer.
                </p>
              </div>
              <img className="me-img" src={profile} title="me" alt="program_img" />

            </div>

            <div className="skills">
              {/* <div className='stack'> */}
              <p className='stack'>Tech Stack</p>
              {/* </div> */}
              <div className="logos">
                <img src={html} title="HTML5" alt="program_img" />
                <img src={css} title="CSS" alt="program_img" />
                <img src={PHP} title="PHP" alt="program_img" />
                <img src={Javascript} title="Javascript" alt="program_img" />
                <img src={bootstrap} title="bootstrap" alt="program_img" />
                <img src={React} title="ReactJS" alt="program_img" />
                <img src={nodejs} title="NodeJS" alt="program_img" />
                <img src={sqlite} title="sqlite" alt="program_img" />
                <img src={mongodb} title="MongoDB" alt="program_img" />
                <img src={firebase} title="firebase" alt="program_img" />
                <img src={dotnet} title="dotnet" alt="program_img" />
              </div>
            </div>
          </div>
        </div>


      </section>
      {/* <Map/> */}

    </div>

  );
}

export default Me;
